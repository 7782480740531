import http from '@/config/api';
import router from '@/router';
import permission from '@/helpers/permission';

export default {
  async getParentEventList({ commit }, payload) {
    await http.get('/events', {
      params: {
        ...payload,
        ...(payload.hasAggregate === undefined ? { hasAggregate: 1 } : { hasAggregate: 0 })
      }
    }).then(response => {
      if (response) {
        const { list, count, existIdentity, existOmise, existSms, existVCN, existNec } = response.data;
        commit('SET_PARENT_EVENT_LIST', list);
        commit('SET_COUNT_PARENT_EVENT_LIST', count);
        commit('SET_EXIST_DATA_EVENT_LIST', {
          existIdentity, existOmise, existSms, existVCN, existNec
        })
      }
    });
  },
  async getParentEventDetail({ commit, dispatch, getters }, subdomain) {
    await http.get(`/event/${subdomain}`).then(response => {
      if (response) {
        commit('SET_PARENT_EVENT_DETAIL', response.data);
        if (permission.isStoreOrGroup() && !getters.parentEventDetail?.allowStoreGroupLogin) {
          dispatch('auth/logout', null, { root: true });
        }
      }
    })
  },
  async getChildEventDetail({ commit, rootGetters }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain || rootGetters['common/subdomain'];
    const directory = params?.directory || router.currentRoute.params.directory;
    await http
      .get(`/event/${subdomain}/child/${directory}`)
      .then((response) => {
        if (response) {
          const data = response.data;
          data.cardImageTemplateId = data.cardImage?.template?.id || '';
          data.cardImageUpload = {
            id: data.cardImage?.imageId,
            url: data.cardImage?.imageUrl,
          };
          data.headerLogo = data.headerLogo || {};
          data.logo = data.logo || {};
          data.hasGroup = data.groups?.length !== 0
          data.usablePeriod = data.usablePeriod || {};
          data.chargeablePeriod = data.chargeablePeriod || {};
          data.paymentablePeriod = data.paymentablePeriod || {};
          data.expirationMonths = data.expirationMonths || '';
          data.chargeType = (!data.chargeType?.payeasy ? {
            ...data.chargeType, payeasy: { use: false } } : (!data.chargeType?.maruPay && !data.chargeType?.store) ?
            { ...data.chargeType, maruPay: { use: false, bankCodes: '' }, store: { use: false } } : !data.chargeType?.maruPay ?
            { ...data.chargeType, maruPay: { use: false, bankCodes: '' } } : !data.chargeType?.store ?
            { ...data.chargeType, store: { use: false } } : data.chargeType) || {
            creditcard: {
              use: false,
            },
            convenienceStore: {
              use: false,
              stores: [],
            },
            payeasy: {
              use: false
            },
            maruPay: {
              use: false,
              bankCodes: '',
            },
            store: {
              use: false,
            }
          }
          data.shopForm = data.shopForm || {};
          commit('SET_CHILD_EVENT_DETAIL', data);
          commit('SET_EVENT_TYPE', data.type);
        } else {
          router.push({ name: '404' });
        }
      });
  },
  async getChildEventList({ commit, rootGetters }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain || rootGetters['common/subdomain'];
    await http.get(`/event/${subdomain}/children`, { params }).then(response => {
      if (response) {
        const { list, count } = response.data;
        commit('SET_CHILD_EVENT_LIST', list);
        commit('SET_COUNT_CHILD_EVENT_LIST', count);
      }
    });
  },
  createParentEvent(_, payload) {
    return http.post('/event', payload, { isMultipartContent: true }).then((response) => response?.data);
  },
  updateParentEvent(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    return http.post(`/event/${subdomain}`, payload, { isMultipartContent: true }).then((response) => response?.data);
  },
  createChildEvent({ commit }, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    return http.post(`/event/${subdomain}/child`, payload, { isEventApi: true, isMessageConverted: true }).then((response) => {
      if (response) commit('RESET_NEW_CHILD_EVENT');
      return response?.data;
    });
  },
  updateChildEvent({ commit }, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    const directory = payload?.directory || router.currentRoute.params.directory;
    return http.post(`/event/${subdomain}/child/${directory}`, payload, { isEventApi: true, isMessageConverted: true }).then((response) => {
      if (response) commit("RESET_CHILD_EVENT_DETAIL");
      return response?.data;
    });
  },
  async setErrorFields({ commit }, payload) {
    commit('SET_ERROR_FIELDS', payload)
  },
  async resetErrorFields({ commit }) {
    commit('RESET_ERROR_FIELDS')
  },
  changeStatusChildEvent({ commit }, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    const directory = payload?.directory || router.currentRoute.params.directory;
    return http.post(`/event/${subdomain}/child/${directory}/validFlag`, payload).then((response) => {
      if (response) commit("SET_CHILD_EVENT_DETAIL", payload);
      return response?.data;
    });
  },
  deleteParentEvent(_, payload) {
    return http.delete(`/event/${payload.subdomain}`);
  },
  deleteChildEvent(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    const directory = payload?.directory || router.currentRoute.params.directory;
    return http.delete(`/event/${subdomain}/child/${directory}`);
  },
  registClients(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    const directory = payload?.directory || router.currentRoute.params.directory;
    return http.post(`/event/${subdomain}/child/${directory}/register-customers`);
  },
  async checkEvent() {
    return http.get("/event", {
      baseURL: process.env.VUE_APP_API_ENDPOINT.replace('management', 'app'),
    });
  },
  async getEventGroupList({ commit }, subdomain) {
    await http.get(`/event/${subdomain}/event-group`).then(response => {
      if (response) {
        commit('SET_EVENT_GROUP_LIST', response.data?.list);
      }
    })
  },
  async getChildEventGroupList({ commit }, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    const directory = payload?.directory || router.currentRoute.params.directory;
    await http.get(`/event/${subdomain}/child/${directory}/event-group`).then(response => {
      if (response) {
        commit('SET_EVENT_GROUP_LIST', response.data?.list);
      }
    })
  },
  getRegistStatus(_, params) {
    return http.get(`queue/status/${params.id}`, { params }).then((response) => {
      return response?.data?.result;
    });
  },
  checkCanUseQueue(_, params) {
    return http.get(`event/${params.id}/checkCanUseQueue`, { params }).then((response) => {
      return response?.data?.result;
    });
  },
  async setInvisibleDashboard(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    const directory = payload?.directory || router.currentRoute.params.directory;
    return http.post(`event/${subdomain}/child/${directory}/invisibleDashboard`, payload);
  },
  createOmiseEvent() {
    return http.get('/event/generate/omise').then((response) => response?.data);
  },
  createLinkageKeyEvent() {
    return http.get('/event/generate/key').then((response) => response?.data);
  },
  async getBankList({ commit }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain;
    const directory = params?.directory || router.currentRoute.params.directory;
    const newDirectory = !params?.directory ? '' : `/${directory}`;
    return await http.get(`event/${subdomain}/banks/child${newDirectory}`, {
      params: { ...params }
    }).then(response => {
      if (response) {
        const { list, count } = response.data;
        commit('SET_BANK_LIST', list);
        commit('SET_COUNT_BANK', count);
      }
      return response;
    });
  },
  updateOrderChildEvent(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    return http.post(`/event/${subdomain}/children/sort`, payload, { isMessageConverted: true });
  },
}
