<template>
  <!--[side]-->
  <div class="side">
    <h1 class="side-logo"><img class="img-fluid" :src="headerLogo" :alt="logoAlt" /></h1>
    <div class="side-body">
      <nav v-if="$permission.isGmoOrOffice() && isGmoOrOfficeUrl" class="side-nav">
        <ul class="side-nav-set">
          <li class="side-nav-item">
            <router-link
              :to="{ name: 'EventList' }"
              :class="{ 'side-nav-link': true, 'is-active': isActive('EventList') }"
            >
              <i class="aikon aikon-drawer side-nav-icon"></i>親イベント一覧
            </router-link>
          </li>
          <li v-if="isRouteWithSubdomain" class="side-nav-item">
            <router-link
              :to="{ name: 'EventDashboardParent' }"
              :class="{ 'side-nav-link': true, 'is-active': isActive('EventDashboardParent') }"
            >
              <i class="aikon aikon-squares side-nav-icon"></i>
              {{ parentEventName }}
            </router-link>
          </li>
          <template v-if="isRouteWithSubdomain && !isRouteWithDirectory">
            <li v-for="item in masterOrClientEventParentNav" :key="item.index" class="side-nav-item">
              <router-link
                v-if="checkPolicy(item.policy, item.shopFlag) && checkTypePoint(item.typePoint)"
                :to="{ name: item.routeName }"
                :class="{ 'side-nav-link': true, 'is-active': isActive(item.routeName, item.pattern) }"
              >
                <i :class="`aikon ${item.icon} side-nav-icon`"></i>{{ item.content }}
              </router-link>
            </li>
          </template>
        </ul>
        <template v-if="isRouteWithDirectory">
          <p class="side-nav-title">{{ childEventDetail.name }}</p>
          <ul class="side-nav-set">
            <li v-for="item in masterOrClientEventChildNav" :key="item.index" class="side-nav-item">
              <router-link
                v-if="checkPolicy(item.policy) && checkCurrencyFlag(item.currencyFlag) && checkEventSummary(item.policy) && checkTypePoint(item.typePoint) && checkHistoryFlag(item.historyFlag)"
                :to="{ name: (!hasShopRole && item.routeName === 'EventSummary') ?  'EventSummaryCompany' : item.routeName }"
                :class="{ 'side-nav-link': true, 'is-active': isActive(item.routeName, item.pattern) }"
              >
                <i :class="`aikon ${item.icon} side-nav-icon`"></i>{{ item.content }}
              </router-link>
            </li>
          </ul>
        </template>
      </nav>
      <nav v-else-if="$permission.isStoreOrGroup() || isGmoOrOfficeLoginStore" class="side-nav">
        <ul class="side-nav-set">
          <li class="side-nav-item">
            <router-link
              :to="{ name: 'ShopEventDashboardParent' }"
              :class="{ 'side-nav-link': true, 'is-active': isActive('ShopEventDashboardParent') }"
            >
              <i class="aikon aikon-squares side-nav-icon"></i>
              {{ parentEventName }}
            </router-link>
          </li>
          <template v-if="!isRouteWithDirectory">
            <li v-for="item in shopOrGroupEventParentNav" :key="item.index" class="side-nav-item">
              <template
                v-if="checkGroupFlag(item.groupFlag) && checkCustomerFlag(item.customerFlag) &&
                  checkPolicy(item.policy) && checkCpmFlag(item.cpmFlag) && checkTypePoint(item.typePoint)"
                >
                <a v-if="item.href" class="side-nav-link" :href="item.href" target="_blank">
                  <i :class="`aikon ${item.icon} side-nav-icon`"></i>{{ item.content }}
                </a>
                <button v-else-if="item.cpmFlag" class="side-nav-link" @click="handleTabCPM">
                  <i :class="`aikon ${item.icon} side-nav-icon`"></i>{{ item.content }}
                </button>
                <router-link
                  v-else
                  :to="{ name: item.routeName }"
                  :class="{ 'side-nav-link': true, 'is-active': isActive(item.routeName, item.pattern) }"
                >
                  <i :class="`aikon ${item.icon} side-nav-icon`"></i>{{ item.content }}
                </router-link>
              </template>
            </li>
          </template>
        </ul>
        <template v-if="isRouteWithDirectory">
          <p class="side-nav-title">{{ childEventDetail.name }}</p>
          <ul class="side-nav-set">
            <li v-for="item in shopOrGroupEventChildNav" :key="item.index" class="side-nav-item">
              <template v-if="checkPolicy(item.policy) && checkTypePoint(item.typePoint)">
                <a v-if="item.href" class="side-nav-link" :href="item.href" target="_blank">
                  <i :class="`aikon ${item.icon} side-nav-icon`"></i>{{ item.content }}
                </a>
                <router-link
                  v-else
                  :to="{ name: item.routeName }"
                  :class="{ 'side-nav-link': true, 'is-active': isActive(item.routeName, item.pattern) }"
                >
                  <i :class="`aikon ${item.icon} side-nav-icon`"></i>{{ item.content }}
                </router-link>
              </template>
          </li>
          </ul>
        </template>
      </nav>
    </div>
    <nav v-if="$permission.isGmoOrOffice() && !isGmoOrOfficeLoginStore" class="side-subMenu">
      <ul class="side-subMenu-set">
        <li v-for="item in masterOrClientMenu" :key="item.index" class="side-subMenu-item">
          <a v-if="item.href" class="side-subMenu-link" :href="item.href" target="_blank">
            <i :class="`aikon ${item.icon} side-subMenu-icon`"></i>{{ item.content }}
          </a>
          <router-link v-else
            :to="{ name: item.routeName }"
            :disabled="checkDisabledIcon(item.pattern)"
            :class="{
              'side-subMenu-link': true,
              'btn-disabled': checkDisabledIcon(item.pattern),
              'is-active': isActive(item.routeName, item.pattern)
            }"
          >
            <i :class="`aikon ${item.icon} side-subMenu-icon`"></i>
          </router-link>
        </li>
      </ul>
    </nav>
    <div class="side-user">
      <div class="side-user-header">
        <p class="side-user-admin" v-if="isGmoOrOfficeLoginStore">
          {{ nameAdmin | upperCase }}
        </p>
        <p class="side-user-authority">
          {{ nameRole | upperCase }}
        </p>
        <p class="side-user-name">{{ accountName }}</p>
      </div>
      <div class="side-user-body">
        <button
          v-if="isGmoOrOfficeLoginStore"
          class="btn btn-w100 btn-white side-user-btn"
          :class="{ 'custom-side-btn': isGmoOrOfficeLoginStore }"
          type="button"
          @click="handleRedirect"
        >
          {{ backBtnName }}
        </button>
        <nav class="side-user-nav">
          <router-link
            v-if="!isGmoOrOfficeLoginStore || isGmoOrOfficeLoginStoreUseCpm"
            class="side-user-link"
            :to="{ name: isGmoOrOfficeLoginStoreUseCpm ? 'AccountUserCpm' : 'AccountUser' }"
          >
            アカウント設定
          </router-link>
          <router-link v-if="isCpmMenuShown" class="side-user-link" :to="{ name: 'AccountCompany' }"
            >{{ $permission.isOffice() ? '事務局/企業設定' : '企業設定' }}</router-link>
        </nav>
        <button class="btn btn-w100 btn-black side-user-btn" :class="{ 'custom-side-btn': isGmoOrOfficeLoginStore }" type="button" @click="logout">ログアウト</button>
      </div>
    </div>
  </div>
  <!--[/side]-->
</template>

<script>
import { mapGetters } from 'vuex';
import { RoleConstants } from '@/constants/role';
import { StatusConstants } from '@/constants/status';
import { getEncodedRefreshToken } from '@/helpers/http';

const defaultQuestionHref = 'https://gmo-housepay.zendesk.com/hc/ja';
export default {
  name: 'Side',
  data: function() {
    return {
      masterOrClientEventParentNav: [
        { routeName: 'EventShop', icon: 'aikon-shop', content: '店舗管理', policy: 'SHOPS', shopFlag: true },
        { routeName: 'EventUser', icon: 'aikon-user', content: '顧客管理', policy: 'CUSTOMERS', pattern: 'EventUser' },
        { routeName: 'EventCoupon', icon: 'aikon-coupon', content: 'クーポン', policy: 'COUPONS' },
        { routeName: 'EventParentPointHistory', icon: 'aikon-point', content: 'ポイント', typePoint: 1, pattern: 'EventParentPoint'},
      ],
      defaultQuestionHref,
      logoAlt: process.env.VUE_APP_APP_NAME,
      logoSrc: process.env.VUE_APP_LOGO_IMAGE,
    };
  },
  computed: {
    ...mapGetters({
      infor: 'auth/infor',
      parentEventDetail: 'event/parentEventDetail',
      childEventDetail: 'event/childEventDetail',
      isShowCustomer: 'event/isShowCustomer',
      isShowCurrency: 'event/isShowCurrency',
      hasShopRole: 'auth/hasShopRole',
      hasCustomersRole: 'auth/hasCustomersRole',
      isClientChild: 'auth/isClientChild',
      isUseCpmParentEvent: 'event/isUseCpmParentEvent',
      isCommonAllEvent: 'event/isCommonAllEvent',
      isOnlyChildEvent: 'event/isOnlyChildEvent',
      isUsePointFlagChildEvent: 'event/isUsePointFlagChildEvent',
      cpmFlag: 'auth/cpmFlag',
      isUsePointFlagParentEvent: 'event/isUsePointFlagParentEvent',
      hasUsersRole: 'auth/hasUsersRole',
      hasEventsRole: 'auth/hasEventsRole',
      isShowMenuCoupon: 'event/isShowMenuCoupon',
      canUsePointFlag: 'event/canUsePointFlag',
      isGmoOrOfficeUrl: 'common/isGmoOrOfficeUrl',
      isGmoOrOfficeLoginStore: 'common/isGmoOrOfficeLoginStore',
      newSubdomain: 'common/subdomain',
      isGmoOrOfficeLoginStoreAndStore: 'common/isGmoOrOfficeLoginStoreAndStore',
      shopDetail: 'shop/shopDetail',
      canUseCpmParentEvent: 'event/canUseCpmParentEvent',
      useTicketFlag: 'event/useTicketFlag',
      hasCurrency: 'event/hasCurrency',
      hasHistoryTicketCompany: 'event/hasHistoryTicketCompany',
      isOldEvent: 'common/isOldEvent',
      hasManageCurrencyRole: 'auth/hasManageCurrencyRole',
      hasPointsRole: 'auth/hasPointsRole',
      hasManageQrRole: 'auth/hasManageQrRole',
      commonSubdomain: 'auth/commonSubdomain',
    }),
    parentEventName() {
      return this.parentEventDetail?.name || '親イベント名';
    },
    isActive() {
      return (routeName, pattern) => {
        if (this.$route.name) {
          return this.$route.name.includes(routeName) || this.$route.name.includes(pattern);
        }
        return false;
      };
    },
    accountName() {
      const nameSei = this.infor.nameSei || '';
      const nameMei = this.infor.nameMei || '';
      return this.isGmoOrOfficeLoginStore ? this.shopDetail?.name : (nameSei + ' ' + nameMei);
    },
    nameRole() {
      if (this.isGmoOrOfficeLoginStore) return '表示中の店舗名';
      if (this.$permission.isStore()) return 'Shop';
      if (this.$permission.isGmo()) return 'Master';
      if (this.$permission.isOffice()) return 'Client';
      if (this.$permission.isGroup()) return 'Group';
      return '';
    },
    nameAdmin() {
      const role = this.$permission.isGmo() ? 'MASTER' : 'CLIENT';
      return `${role}にてログイン中`;
    },
    isRouteWithSubdomain() {
      return (
        this.$route.params.subdomain &&
        this.$route.name &&
        !this.$route.name.startsWith('Setting') &&
        this.$route.name !== 'EventList' &&
        !this.$route.name.includes('Notification') &&
        !this.$route.name.includes('404') &&
        !this.$route.name.includes('AccountCompany') &&
        !this.$route.name.includes('AccountUser') &&
        !this.$route.name.includes('AccountUserCpm') &&
        !this.$route.name.includes('AccountUserLog') &&
        !this.$route.name.includes('AccountUserCpmRegister') &&
        !this.$route.name.includes('AccountUserCpmEdit')
      );
    },
    isRouteWithDirectory() {
      return (
        this.$route.params.directory &&
        this.$route.name &&
        !this.$route.name.includes('SettingEventChild')
      );
    },
    headerLogo() {
      if (this.isRouteWithDirectory && this.childEventDetail.logo?.url) {
        return this.childEventDetail.logo.url
      }
      return this.logoSrc;
    },
    isShowMenuCPM() {
      return this.isUseCpmParentEvent && !this.$permission.isGmoOrOffice();
    },
    cpmHref() {
      return `${this.infor?.cpmUrl}/management?callback=${getEncodedRefreshToken()}`;
    },
    shopOrGroupEventParentNav() {
      return [
        { routeName: 'ShopSettingInfo', icon: 'aikon-shop', content: '店舗設定', pattern: 'ShopSetting' },
        { routeName: 'ShopUser', icon: 'aikon-user', content: '顧客管理', customerFlag: true, groupFlag: true, policy: 'CUSTOMERS' },
        { routeName: 'ShopGalleryCategory', icon: 'aikon-image', content: 'ギャラリー', pattern: 'ShopGallery', policy: 'GALLERY' },
        { routeName: 'ShopMenuCategory', icon: 'aikon-menu', content: 'メニュー', pattern: 'ShopMenu', policy: 'MENU' },
        { routeName: 'ShopCoupon', icon: 'aikon-coupon', content: 'クーポン', groupFlag: true, policy: 'COUPON' },
        { routeName: 'ShopParentPointHistory', icon: 'aikon-point', content: 'ポイント履歴', groupFlag: true, typePoint: 1 },
        { icon: 'aikon-squares', content: 'CPMカード払い', cpmFlag: true },
        { routeName: 'ShopQr', icon: 'aikon-squares', content: 'QRコード', groupFlag: true },
        { href: this.parentQuestionUrl, icon: 'aikon-help', content: 'マニュアル', groupFlag: true },
      ]
    },
    shopOrGroupEventChildNav(){
      return [
        { routeName: 'ShopEventDashboardChild', icon: 'aikon-squares', content: 'ダッシュボード' },
        { routeName: 'ShopChildPointHistory', icon: 'aikon-point', content: 'ポイント履歴', typePoint: 2 },
        { routeName: 'ShopHistory', icon: 'aikon-history', content: '支払履歴', policy: 'TRANSACTIONS' },
        { routeName: 'ShopSummary', icon: 'aikon-chart_bar', content: '集計', 'policy': 'AGGREGATES' },
        { routeName: 'ShopNews', icon: 'aikon-news', content: 'お知らせ', policy: 'NEWS' },
        { href: this.childQuestionUrl, icon: 'aikon-help', content: 'マニュアル' },
      ];
    },
    masterOrClientMenu() {
      const settingRouteName = !this.hasUsersRole ? 'SettingEvent' : 'SettingUser';
      const hiddenRouteNames = ['EventList', 'Notification', 'AccountUser', 'AccountCompany', 'NotificationRegister'];
      const isIconQuestionNotShown = (this.$route.name?.includes('SettingUser') || this.$route.name?.includes('SettingEvent') || hiddenRouteNames.includes(this.$route.name)) && this.$permission.isGmoOrOffice();
      let data = [
        { href: this.questionUrl, icon: 'aikon-question' },
        { routeName: settingRouteName, icon: 'aikon-gear', pattern: 'Setting' },
        { routeName: 'Notification', icon: 'aikon-speaker' },
      ];
      if (this.$permission.isGmo() || isIconQuestionNotShown) data = data.filter(e => !e?.href);
      return data;
    },
    questionUrl() {
      return !this.$route.params?.directory ? this.parentQuestionUrl : this.childQuestionUrl;
    },
    parentQuestionUrl() {
      return this.parentEventDetail?.urlFaq || this.defaultQuestionHref;
    },
    childQuestionUrl() {
      return this.childEventDetail?.urlFaq || this.parentEventDetail?.urlFaq || this.defaultQuestionHref;
    },
    backBtnName() {
      return (this.$permission.isGmo() ? 'MASTER' : 'CLIENT') + 'に戻る';
    },
    appUrl() {
      return this.infor?.appUrl || this.parentEventDetail?.appUrl || '';
    },
    adminUrl() {
      return `${this.appUrl}event/${this.newSubdomain}/shop`;
    },
    isGmoOrOfficeLoginStoreUseCpm() {
      return this.isGmoOrOfficeLoginStore && this.isUseCpmParentEvent;
    },
    isCpmMenuShown() {
      return this.$permission.isStoreOrOffice() && !this.isGmoOrOfficeLoginStore;
    },
    masterOrClientEventChildNav() {
      const currencyRouteName = (!this.hasCustomersRole && !this.$permission.isGmo()) || !this.hasManageCurrencyRole ? 'EventCurrencyQr' : 'EventCurrency';
      return [
        { routeName: 'EventDashboardChild', icon: 'aikon-dashboard', content: 'ダッシュボード' },
        { routeName: 'EventStoreAttendList', icon: 'aikon-shop', content: '参加店舗リスト', policy: 'SHOPS', pattern: 'EventStoreAttend' },
        { routeName: currencyRouteName, icon: 'aikon-creditcard_fill', content: '通貨管理', policy: 'CURRENCY', currencyFlag: true, pattern: 'EventCurrency' },
        { routeName: 'EventChildPointHistory', icon: 'aikon-point', content: 'ポイント', typePoint: 2, pattern: 'EventChildPoint'},
        { routeName: 'EventHistoryBuy', icon: 'aikon-history', content: '履歴', pattern: 'EventHistory', policy: 'TRANSACTION', historyFlag: true },
        { routeName: 'EventSummary', icon: 'aikon-chart_bar', content: '集計', policy: 'AGGREGATE' },
        { routeName: 'News', icon: 'aikon-news', content: 'お知らせ' },
      ]
    }
  },
  methods: {
    async handleRedirect() {
      if (localStorage.getItem('refreshToken')) {
        window.location.href = this.adminUrl + `?callback=${getEncodedRefreshToken()}`;
      } else {
        window.location.href = this.adminUrl;
      }
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('token');
      this.$store.commit('common/SET_IS_OLD_EVENT', null);
    },
    async handleTabCPM() {
      await this.$store.dispatch('event/getParentEventDetail', this.commonSubdomain);
      if (!this.parentEventDetail?.hasJoinEventCpm) {
        this.$router.push({ name: '404' });
        return;
      }
      window.open(this.cpmHref, '_blank');
      this.$store.commit('auth/RESET_LOCAL_STORAGE');
      this.$router.push({ name: 'Login' }).then(() => this.$message.showError('outOfLoginSession')).catch(() => {});
    },
    async logout() {
      if (this.isGmoOrOfficeLoginStore && !this.isOldEvent) {
        window.location.href = `${this.appUrl}login`;
      }
      await this.$store.dispatch('auth/logout');
      this.$parent.transitionName = '';
    },
    checkPolicy(policy, shopFlag = null) {
      if (policy && RoleConstants.ROLE_SHOPS_OFFICE.toUpperCase() === policy && shopFlag && this.isClientChild) {
        return false;
      }
      if (policy && this.infor.kind === RoleConstants.ROLE_OFFICE) {
        const validPolicy = (this.isGmoOrOfficeLoginStore && policy === 'COUPON') || ['TRANSACTION', 'AGGREGATE'].includes(policy);
        const newPolicy = validPolicy ? `${policy}S` : policy;
        if (this.isGmoOrOfficeLoginStore && ['NEWS', 'GALLERY', 'MENU'].includes(policy)) return true;
        return this.infor.role.includes(RoleConstants["ROLE_" + newPolicy + "_OFFICE"]) && (newPolicy === 'COUPONS' ? this.hasShopRole : true);
      }
      if (policy && RoleConstants.ROLE_STORE_OR_GROUP.includes(this.infor.kind) && this.parentEventDetail?.shopMenus) {
        if (['TRANSACTIONS', 'AGGREGATES', 'CUSTOMERS'].includes(policy)) return true;
        return this.parentEventDetail.shopMenus[policy.toLowerCase()] && (policy !== 'COUPON' || this.isShowMenuCoupon);
      }
      return true;
    },
    checkEventSummary(policy) {
      const isClientChildNoShopRole = this.isClientChild && !this.infor?.role?.includes(RoleConstants.ROLE_SHOPS_OFFICE);
      const isClientNoShopRole = this.$permission.isOffice() && !this.infor?.role?.includes(RoleConstants.ROLE_SHOPS_OFFICE);
      return !(policy === (RoleConstants.ROLE_AGGREGATES_OFFICE).toUpperCase() &&
        (isClientChildNoShopRole || (isClientNoShopRole && !this.isShowCurrency)));
    },
    checkCurrencyFlag(currencyFlag) {
      const isClientUseTicketFlag = (this.hasCustomersRole && (this.hasManageQrRole || this.hasManageCurrencyRole)) || (this.hasManageQrRole && !this.$permission.isGmo());
      return !currencyFlag || (currencyFlag && this.isShowCurrency && isClientUseTicketFlag);
    },
    checkHistoryFlag(historyFlag) {
      return !historyFlag || !(historyFlag && this.useTicketFlag && !this.hasCurrency && !this.hasHistoryTicketCompany && this.isClientChild);
    },
    checkDisabledIcon(pattern) {
      return pattern === 'Setting' && !this.hasEventsRole && !this.hasUsersRole;
    },
    checkTypePoint(typePoint) {
      const isParentPointMenu = (typePoint === StatusConstants.pointType.all.value) &&
        (this.isCommonAllEvent && !this.isClientChild && this.isUsePointFlagParentEvent && this.canUsePointFlag) && (this.hasPointsRole || this.$permission.isStoreOrGroup());
      const isChildPointMenu = (typePoint === StatusConstants.pointType.special.value) &&
        (this.isOnlyChildEvent && this.isUsePointFlagChildEvent && this.canUsePointFlag) && (this.hasPointsRole || this.$permission.isStoreOrGroup());
      return !typePoint || isParentPointMenu || isChildPointMenu;
    },
    checkCustomerFlag(customerFlag) {
      return !customerFlag || (customerFlag && this.isShowCustomer);
    },
    checkCpmFlag(cpmFlag) {
      return !cpmFlag || (cpmFlag && this.isShowMenuCPM && this.parentEventDetail?.hasJoinEventCpm) ;
    },
    checkGroupFlag(groupFlag) {
      return this.$permission.isGroup() ? groupFlag : true;
    }
  },
};
</script>

<style scoped>
.custom-side-btn {
  font-size: 0.75rem !important;
}
</style>
