<template>
  <section class="panel" :class="[setType, setHeight, customClass]">
    <header class="panel-header" :class="{ 'justify-content-between': between }" v-if="this.$slots.headline || this.$slots.headerContent">
      <h2 class="headline panel-headline" :class="{ 'w-100': isPointEvent }"><slot name="headline"></slot></h2>
      <slot name="headerContent"></slot>
      <div class="headline panel-headline"><slot name="headlineStatus"></slot></div>
    </header>
    <slot v-if="this.$slots.tab" name="tab"></slot>
    <div
      v-if="this.type === 'sheet'"
      class="panel-body"
      :class="[setColor, setLink, setPadding, { 'panel-body-point': isPoint, 'pd-0': hasDataPoint }]"
    >
      <slot name="body"></slot>
    </div>
    <div class="panel-content" :class="setLink" v-if="this.type === 'board'">
      <div class="panel-body" :class="[setColor, setPadding, { 'panel-body-center': center, 'pd-0': hasDataPoint }]">
        <slot name="body"></slot>
      </div>
      <span v-if="this.$slots.tooltip"><slot name="tooltip"></slot></span>
      <div class="panel-content-footer" v-if="this.$slots.contentFooter">
        <slot name="contentFooter"></slot>
      </div>
    </div>
    <footer class="panel-footer" :class="{ 'no-border': hasFooterBorder }" v-if="this.$slots.footer"><slot name="footer"></slot></footer>
  </section>
</template>

<script>
export default {
  name: 'Panel',
  props: {
    type: String,
    customClass: String,
    color: String,
    link: {
      default: false,
      type: Boolean,
    },
    padding: {
      default: true,
      type: Boolean,
    },
    center: {
      default: false,
      type: Boolean,
    },
    height: {
      default: true,
      type: Boolean,
    },
    between: {
      default: false,
      type: Boolean,
    },
    isPoint: {
      default: false,
      type: Boolean
    },
    isPointEvent: {
      default: false,
      type: Boolean
    },
    hasDataPoint: {
      default: false,
      type: Boolean
    },
    isNotBorderShadow: {
      default: false,
      type: Boolean
    },
    hasFooterBorder: {
      default: false,
      type: Boolean
    },
  },
  computed: {
    setType: function() {
      switch (this.type) {
        case 'sheet':
          return 'panel-sheet';
        case 'board':
          return 'panel-board';
      }
      return false;
    },
    setColor: function() {
      if (this.color != undefined) {
        switch (this.color) {
          case 'blue':
            return 'panel-blue';
          case 'green':
            return 'panel-green';
          case 'red':
            return 'panel-red';
          case 'yellow':
            return 'panel-yellow';
          case 'purple':
            return 'panel-purple';
          case 'deeppink':
            return 'panel-deeppink';
        }
      }
      return false;
    },
    setLink: function() {
      return {
        'panel-link': this.link,
        'panel-no-shadow': this.isNotBorderShadow,
      };
    },
    setPadding: function() {
      return {
        'panel-body-p0': !this.padding,
      };
    },
    setHeight: function() {
      return {
        'panel-height-auto': !this.height,
      };
    },
  },
};
</script>

<style scoped>
.panel-no-shadow {
  box-shadow: none !important;
}

.no-border {
  border: none !important;
}
</style>
