import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import { CommonConstants } from '@/constants/common';

const formatLocalString = (val) => {
  if (!val || !Number(val)) {
    return 0;
  }
  return Number(val).toLocaleString();
};

const formatBetweenTwoDates = (from, to) => {
  if (!from || !to) {
    return '';
  }
  return formatDate(from) + '〜' + formatDate(to);
};

const formatDateAndTime = (date) => {
  if (!date) {
    return '';
  }
  return format(new Date(date), 'yyyy/MM/dd HH:mm');
};

const formatDate = (date) => {
  if (!date) {
    return '';
  }
  return format(new Date(date), 'yyyy/MM/dd');
};

const formatMonthYear = (date) => {
  if (!date) {
    return '';
  }
  return format(new Date(date), 'yyyy/MM');
};

const formatUploadDate = (date) => {
  if (!date) {
    return '';
  }
  return format(new Date(date), 'yyyy-MM-dd')
}

const formatJapaneseDate = (date) => {
  if (!date) {
    return '';
  }
  return format(new Date(date), 'yo MMM do', { locale: ja });
};

const formatArray = (list, key) => {
  if (!list || !Array.isArray(list)) {
    return '';
  }
  return list.map((item) => item[key]).join('、');
};

const stripTags = (string) => {
  return string.replace(CommonConstants.STRIP_TAGS, "");
};

const endline = (string, number = 25) => {
  if (!string) return '';
  const newRegExp = new RegExp(`.{1,${number}}`, 'g');
  return (string.match(newRegExp) || []).join('\n')
}

const addThreeDotEndLine = (string, number = 20) => {
  if(string.length > number) {
    return string.slice(0, number) + '...';
  }
  return string;
}

const breakLineWithComma = (string) => {
  if (!string) return '';
  return string.replace(CommonConstants.COMMA_REGEX, '\n');
}

const breakLineWithDash = (string) => {
  if (!string || typeof(string) !== 'string') return '';
  return string.replace(CommonConstants.DASH_REGEX, '\n');
}


const roundedFirstDecimal = (number) => {
  if (!number) {
    return '';
  }
  return Math.floor(Number(number) * 10) / 10;
}

const convertBooleanValues = (obj) => {
  const result = {};
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'boolean') {
      result[key] = value ? 1 : 0;
    } else if (Array.isArray(value)) {
      result[key] = value.map((item) =>
        typeof item === 'boolean' ? (item ? 1 : 0) : item
      );
    } else if (typeof value === 'object' && value !== null) {
      result[key] = convertBooleanValues(value);
    } else {
      result[key] = value;
    }
  }
  return result;
}

const convertStringToNumberArray = (data) => {
  return data?.map(e => Number(e));
}

const generateRandomId = () => {
  return Math.random().toString(36).substring(2);
};

const convertNumberToArray = (number) => {
  if (!number || number <= 0) {
    return [];
  }

  return Array.from({ length: number }, (_, index) => index + 1);
};

const formatDashString = (string) => {
  if (!string) {
    return '-';
  }
  return string;
};

const convertNumberToArrayWithKey = (number, startNumber = 1) => {
  if (!number || number <= 0) {
    return [];
  }

  return Array.from({ length: number }, (_, index) => ({
    key: generateRandomId(),
    value: index + startNumber
  }));
};

const convertStringsToDateTime = (year, month, day, hour, minute = '00') => {
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedDay = day < 10 ? `0${day}` : `${day}`;
  const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
  const formattedDate = `${year}-${formattedMonth}-${formattedDay} ${formattedHour}:${minute}`;
  return formattedDate;
};

export {
  formatLocalString,
  formatBetweenTwoDates,
  formatDateAndTime,
  formatJapaneseDate,
  formatArray,
  formatDate,
  formatUploadDate,
  formatMonthYear,
  stripTags,
  endline,
  addThreeDotEndLine,
  breakLineWithComma,
  roundedFirstDecimal,
  convertBooleanValues,
  convertStringToNumberArray,
  convertNumberToArray,
  generateRandomId,
  formatDashString,
  convertNumberToArrayWithKey,
  breakLineWithDash,
  convertStringsToDateTime,
};
