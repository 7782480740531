import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialEvent = () => ({
  parentEventList: {
    list: [],
    count: 0,
    existData: {}
  },
  childEventList: {
    list: [],
    count: 0,
  },
  bankList: {
    list: [],
    count: 0,
  },
  parentEventDetail: {},
  childEventDetail: {},
  newEvent: {},
  errorFields: {},
  eventType: null,
  eventGroupList: {
    list: []
  },
  childEventType: null,
});

export default {
  state: initialEvent(),
  mutations,
  actions,
  getters,
  namespaced: true
};
